import {BodyElement, ReadyEvent} from "../Utilities";

window.addEventListener(ReadyEvent, () => {
    const banner = document.querySelector('.usi-eyelid-banner');

    if(banner){
        let bannerState = {visible: true};

        const handleBannerStateUpdate = () => {
            BodyElement.classList.toggle('show-eyelid', bannerState.visible);
        };

        const updateBannerState = (newState) =>{
            bannerState = {...newState};
            handleBannerStateUpdate();
        };

        banner.querySelector('.eyelid-banner__close').addEventListener('click', evt => {
            updateBannerState({visible: false});
        });

        handleBannerStateUpdate();
    }
});
