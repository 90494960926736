import Lottie from 'lottie-web';
import {Backend, RatiosDone} from "../Utilities";

const createBMSVG = el => {

    if(el.dataset.bmPath !== undefined){
        const path = `${Backend.theme}/bodymovin/${el.dataset.bmPath}`;

        const t = parseFloat(el.dataset.threshold);
        const thresh = isNaN(t)? 0.5 : t;

        const bmAnimation = Lottie.loadAnimation({
            container: el,
            renderer: "svg",
            loop: false,
            autoplay: false,
            path: path
        });

        const observer = new IntersectionObserver((records, observer) => {
            for (const record of records){
                if(record.intersectionRatio > 0){
                    if(!el.classList.contains('played')){
                        bmAnimation.play();
                        el.classList.add('played');
                    }
                }

            }
        }, {threshold: [thresh]});

        observer.observe(el);

    } else{
        console.log('undefined data path for bm svg at: ', el);
    }
};


window.addEventListener(RatiosDone, evt => {
    document.querySelectorAll('.bm-svg').forEach(item => createBMSVG(item));
});