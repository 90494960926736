import {ReadyEvent, RootElement, IsMobile, RegisterEnterHandler} from "../Utilities";

const header = RootElement.querySelector('#global-header');
const ddOpenClass = 'sub-menu-open';
const itemHoveringClass = 'is-hovering';
const dropDownItems = header.querySelectorAll('.menu-item-has-children');
const topLevelLinks = [...dropDownItems].map(item => item.querySelector('a'));
const navList = header.querySelector('#header-nav-items');

const closeAllSubNavs = () => {
    const submenus = header.querySelectorAll(`.${ddOpenClass}`);
    if(submenus.length){
        submenus.forEach(menu => menu.classList.remove(ddOpenClass));
        document.activeElement.blur();
    }
};

const handleFocusIn = () => {
    dropDownItems.forEach(item => {
        if(item.contains(document.activeElement)){
            item.classList.add(ddOpenClass);
        } else {
            if(!item.classList.contains(itemHoveringClass)){
                item.classList.remove(ddOpenClass);
            }
        }
    });
};

const handlePointerEnter = item => {
    item.classList.add(itemHoveringClass);
    item.classList.add(ddOpenClass);
};

const handlePointerLeave = item => {
    item.classList.remove(itemHoveringClass);
    if(!item.contains(document.activeElement)){
        item.classList.remove(ddOpenClass);
    }
};

const dropDowns = () => {

    header.addEventListener('focusin', evt => {
        if(navList.contains(document.activeElement)){
            handleFocusIn();
        } else {
            navList.querySelectorAll(`.${ddOpenClass}`)
                .forEach(item => item.classList.remove(ddOpenClass));
        }
    });

    dropDownItems.forEach(item => {
        const enter = 'onpointerenter' in item ? 'pointerenter' : 'mouseenter';
        const leave = 'onpointerleave' in item ? 'pointerleave' : 'mouseleave';

        item.addEventListener(enter, evt => handlePointerEnter(item));
        item.addEventListener(leave, evt => handlePointerLeave(item));
    });

    window.addEventListener('keyup', ev => {
        const key = ev.key;
        const code = ev.keyCode ? ev.keyCode : ev.which;

        if( key === 'Escape' || code === 27){
            // you hit the ESC key
            closeAllSubNavs();
        }
    })
};

const mobileHeader = () =>{
    const headerRight = header.querySelector('.header-right');
    const mobNavOpen = header.querySelector('.mobile-nav-btn');
    const mobNavClose = headerRight.querySelector('.mobile-nav-btn');

    const mobNavOpenClass = 'mobile-nav-open';
    //const scrollLockClass = 'no-scroll';

    const openNav = () => {
        headerRight.classList.add(mobNavOpenClass);
        //document.body.classList.add(scrollLockClass);
    };

    const closeNav = () => {
        headerRight.classList.remove(mobNavOpenClass);
        //document.body.classList.remove(scrollLockClass);
    };

    mobNavOpen.addEventListener('click', openNav);
    mobNavClose.addEventListener('click', closeNav);

    RegisterEnterHandler(mobNavOpen, openNav);
    RegisterEnterHandler(mobNavClose, closeNav);

    headerRight.addEventListener('focusin', openNav);
    //headerRight.addEventListener('focusout', closeNav);
};

window.addEventListener(ReadyEvent, evt => {
    if(!IsMobile){
        dropDowns();
    }
    mobileHeader();

    topLevelLinks.forEach(link => {
        link.dataset.url = link.href;
    });

    //adding and removing mobile nav class
    const handleMQL = mql => {
        const action = mql.matches ? 'add' : 'remove';
        header.classList[action]('mobile');

        topLevelLinks.forEach(link => {
            link.href = mql.matches ? '#' : link.dataset.url;
            const label = mql.matches ? `Expand Item: ${link.innerText}` : '';
            link.setAttribute('aria-label', label);
        });
    };

    const mql = matchMedia("screen and (max-width: 1130px)");
    if(mql.onchange){
        mql.addEventListener('change', handleMQL);
    } else {
        mql.addListener(handleMQL);
    }

    handleMQL(mql);
});