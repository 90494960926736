export const RootElement = document.querySelector('body');
export const BodyElement = document.body;
export const ReadyEvent = 'DOMContentLoaded';
export const LoadEvent = 'load';
export const RatiosDone = 'usi.ratios.done';
export const Backend = USI_BACKEND; //comes from PHP localized script
export let EnterHandlers = [];
export const RegisterEnterHandler = (element, handler) => EnterHandlers.push({ element, handler });

export const throwEvent = (eventType, element = window) => {
    let event;
    if(typeof(Event) === 'function') {
        event = new Event(eventType);
    }else{
        event = document.createEvent('Event');
        event.initEvent(eventType, true, true);
    }

    element.dispatchEvent(event);
};

const usiEvents = {
    vpChange: 'usi.wireless.viewportchange'
};

const mobileCutOff = 768;
const mobileMQL = matchMedia(`screen and (max-width: ${mobileCutOff}px)`);

export let IsMobile = mobileMQL.matches;

const handleMQChange = e => {
    IsMobile = e.matches;

    //trigger event for change
    const event = new CustomEvent(usiEvents.vpChange, {detail: {isMobile: IsMobile} });
    window.dispatchEvent(event);
};

if(mobileMQL.onchange){
    mobileMQL.addEventListener('change', handleMQChange)
} else {
    mobileMQL.addListener(handleMQChange);
}

const pageconent = document.querySelector('.pagecontent');
pageconent.id = 'usi-main-content';
pageconent.tabIndex = '0';
pageconent.setAttribute('role', 'main');