//ratio holders
import {ReadyEvent, BodyElement, RatiosDone, throwEvent} from "../Utilities";

window.addEventListener(ReadyEvent, evt=> {
    const ratioHolders = document.querySelectorAll('.ratio-holder');
    ratioHolders.forEach(holder => {
        const rw = holder.dataset.ratioWidth;
        const rh = holder.dataset.ratioHeight;

        const ratio = (rh / rw) * 100;
        holder.querySelector('.ratio-holder__inner').style.paddingBottom = `${ratio}%`;
    });

    setTimeout(() => {
        BodyElement.style.opacity = '1';
        throwEvent(RatiosDone);
    }, 150);
});