import {ReadyEvent, IsMobile, RegisterEnterHandler} from "../Utilities";
const accClasses = {
    wrapper: 'usi-accordion',
    cell: 'accordion-cell',
    head: 'cell__head',
    body: 'cell__body',
    button: 'cell-button',
    open: 'open'
};

const updateCellState = (cell, isOpen) => {
    const classAction = isOpen ? 'remove' : 'add';
    const head = cell.querySelector(`.${accClasses.head}`);
    const body = cell.querySelector(`.${accClasses.body}`);
    const button = cell.querySelector(`.${accClasses.button}`);

    if(button){
        button.innerText = isOpen ? '+' : '-';
    }

    cell.classList[classAction](accClasses.open);
    body.style.height = isOpen ? '0px' : `${body.scrollHeight}px`;

    if(!isOpen){
        body.setAttribute('aria-hidden', 'false');
        head.setAttribute('aria-expanded', 'true');
        body.tabIndex = "0";
    } else {
        body.setAttribute('aria-hidden', 'true');
        head.setAttribute('aria-expanded', 'false');
        body.removeAttribute('tabIndex');
    }
};

const handleCellClick = (cell)=> {
    const cellOpen = cell.classList.contains(accClasses.open);
    updateCellState(cell, cellOpen);
};

const createAccordion = (accordion) => {
    const cells = accordion.querySelectorAll(`.${accClasses.cell}`);
    cells.forEach(cell => {
        const head = cell.querySelector(`.${accClasses.head}`);
        const body = cell.querySelector(`.${accClasses.body}`);

        head.tabIndex = "0";
        //body.tabIndex = "0";

        head.setAttribute('aria-expanded', 'false');
        body.setAttribute('aria-hidden', 'true');

        head.addEventListener('mousedown', evt => evt.preventDefault());
        head.addEventListener('click', evt => {
            evt.preventDefault();
            handleCellClick(cell)
        });

        if(!accordion.classList.contains('nav-items')){
            RegisterEnterHandler(head, evt => {
                evt.preventDefault();
                handleCellClick(cell);
            });
        }
    });

};

window.addEventListener(ReadyEvent, evt => {
    document.querySelectorAll(`.${accClasses.wrapper}`).forEach(accordion => createAccordion(accordion));

    //convert navigation to accordion by adding appropriate accordion classes if we are on a mobile device;
    if(document.getElementById('global-header').classList.contains('mobile')){
        const navigation = document.querySelector('#header-nav-items');
        navigation.classList.add(accClasses.wrapper);
        navigation.querySelectorAll(':scope > .menu-item').forEach(item => item.classList.add(accClasses.cell));

        //todo: add aria label to the head for the navigation
        navigation.querySelectorAll(':scope > .menu-item > a').forEach(link => link.classList.add(accClasses.head));
        navigation.querySelectorAll(':scope > .menu-item > .sub-menu').forEach(sub => sub.classList.add(accClasses.body))

        createAccordion(navigation);
    }

});