import {ReadyEvent, RegisterEnterHandler} from "../Utilities";
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();



window.addEventListener(ReadyEvent, evt => {
    const links = document.querySelectorAll('.jump-to');

    const scrollToElement = el => {
        const header = document.getElementById('global-header');
        const top = parseFloat(el.getBoundingClientRect().top);
        const sTop = window.scrollY;
        const endPosition = (top + sTop) - header.clientHeight;

        window.scroll({
            top:endPosition,
            left: 0,
            behavior: 'smooth'
        });

        setTimeout(()=> {
            const titleElement = el.querySelector('.faq-group__title');
            if(titleElement) titleElement.focus();
            else el.focus();
        }, 500);

    };

    links.forEach(link => {
        link.tabIndex = '0';
        link.setAttribute('aria-label', `Jump to ${link.innerText}`);

        link.addEventListener('mousedown', e => e.preventDefault());
        link.addEventListener('click', evt1 => {
            evt1.preventDefault();
            const el = document.querySelector(link.dataset.to);
            //console.log(el);
            scrollToElement(el);

        });

        RegisterEnterHandler(link, evt => {
            evt.preventDefault();
            const el = document.querySelector(link.dataset.to);
            link.blur();
            scrollToElement(el);
        });

    });


});