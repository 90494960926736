import {RatiosDone, ReadyEvent} from "../Utilities";
import bindAll from "lodash-es/bindAll";

const threeStepSections = document.querySelectorAll('.three-step');
console.log(IntersectionObserver);
class ThreeStep {
    state = {
        currentStep: -1
    };

    progressTimeout = 0;
    stepClasses = ['step-1', 'step-2', 'step-3'];
    sentinelClasses = {
        general: 'step-wrap__sentinel',
        top: 'step-wrap__sentinel--top',
        bottom: 'step-wrap__sentinel--bottom'
    };
    scrollable = true;
    lastScrollRelease = Date.now();

    constructor(props){
        this.props = props;

        this.mainWrap = this.props.element.querySelector('.three-step-wrap');
        this.topSentinel = this.props.element.querySelector('.sentinel--top');
        this.bottomSentinel = this.props.element.querySelector('.sentinel--bottom');
        bindAll(this, ['nextStep', 'prevStep', 'setStep', 'observeWrap', "setState"]);

        this.initListeners();

    }

    initListeners(){
        const indicators = this.props.element.querySelectorAll('.step-indicator');
        indicators.forEach(indicator => {
            const index = Array.prototype.indexOf.call(indicators, indicator);
            ['click', 'focus'].forEach(evt => indicator.addEventListener(evt, e => this.setStep(index)));
        });

        this.observeWrap();
    }

    setStep(step){
        clearTimeout(this.progressTimeout);
        this.setState({currentStep: step});

        if(step < this.stepClasses.length - 1){
            this.progressTimeout = setTimeout(() => this.setStep( step + 1 ), 3000);
        }
    }

    nextStep(){
        if(this.state.currentStep < (this.stepClasses.length - 1)){
            const step = this.state.currentStep + 1;
            this.setStep(step);
        }
    }

    prevStep(){
        if(this.state.currentStep > 0){
            const step = this.state.currentStep - 1;
            this.setStep(step);
        }
    }

    observeWrap(){

        const tObserver = new IntersectionObserver((records, observer) => {
            for (const record of records){
                const targetInfo = record.boundingClientRect;
                const rootInfo = record.rootBounds;

                if(targetInfo.top <= rootInfo.bottom){

                    setTimeout(() => {
                        this.setStep(0);
                    }, 2500);

                    //cancel the observer once it starts
                    observer.unobserve(this.topSentinel);
                }
            }
        }, {threshold: [1]});

        const bObserver = new IntersectionObserver((records, observer)=>{
            for(const record of records){
                const targetInfo = record.boundingClientRect;
                const rootInfo = record.rootBounds;

                if(targetInfo.bottom <= rootInfo.top){
                    console.log('item leaving');
                }

            }
        }, {threshold: [.8]});

        tObserver.observe(this.topSentinel);
        //bObserver.observe(this.bottomSentinel);
    }

    setState(newState){
        this.state = {
            ...this.state,
            ...newState
        };

        this.handleStateUpdate();
    }

    handleStateUpdate(){

        const currentClass = this.stepClasses[this.state.currentStep];
        this.stepClasses.forEach(item => this.mainWrap.classList.remove(item));
        this.mainWrap.classList.add(currentClass);
    }
}

window.addEventListener(RatiosDone, evt => {
    threeStepSections.forEach(element => new ThreeStep({element}));
});