import {EnterHandlers, ReadyEvent} from "../Utilities";

const handleEnterKey = evt => {
    const itemWithHandler = EnterHandlers.find( item => item.element === document.activeElement );

    if(itemWithHandler){
        itemWithHandler.handler(evt);
    }
};

window.addEventListener(ReadyEvent, evt => {
    console.log(EnterHandlers);

    window.addEventListener('keyup', ev => {
        const key = ev.key;
        const code = ev.keyCode ? ev.keyCode : ev.which;

        if(key === 'Enter' || code === 13){
            //you hit the enter key
            handleEnterKey(ev);
        }
    });
});